import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import bg22 from '../../../assets/images/background/bg-22.png'
import menusnoodleone from '../../../assets/images/resource/menus-noodle.jpg'
import {getDatabase, onValue, ref} from "firebase/database";

function Noodle() {
    const [iletisimVeriler, setIletisimVeriler] = useState({});
    const [currentId, setCurrentId]=useState('');

    useEffect(() => {
        const yemeklerRef = ref(getDatabase(),'lobimenu');
        onValue(yemeklerRef, (snapshot) => {
            if(snapshot.exists()){
                const data = snapshot.val();
                const filteredData = {};
                Object.keys(data).forEach((key) => {
                    if (data[key].kategori === 'Noodle') {
                        filteredData[key] = data[key];
                    }
                });
                setIletisimVeriler(filteredData);
            }
            console.log(iletisimVeriler);
        });
    }, []);

    return (

        <>
            <section id="noodles" className="menu-one">
                <div className="left-bg"><img src={bg22} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Nooodles</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Noodle</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menusnoodleone} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                {Object.keys(iletisimVeriler).map(id=>{
                                    return <div className="dish-block" key={id}>
                                        <div className="inner-box">
                                            <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{iletisimVeriler[id].isim}</Link></h5></div><div className="price"><span>{iletisimVeriler[id].fiyat}</span><i
                                                className="fas fa-turkish-lira-sign fa-2xs align-baseline"></i></div></div>
                                            <div className="text desc"><Link to="#">{iletisimVeriler[id].detay}</Link></div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>


    )
}

export default Noodle