import React from 'react';
import Backgroundimage from '../../assets/images/background/image-4.jpg';
import { Link } from 'react-router-dom'
import logonew from '../../assets/images/cp-logo.png'


function Main() {
    return (
        <>

            <footer className="main-footer">
                <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}> </div>
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">

                            <div className="footer-col info-col col-lg-6 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Crowne Plaza - Cappadocia, Menu"><img src={logonew} alt="" title="Crowne Plaza - Cappadocia, Menu" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li>Crowne Plaza - Cappadocia</li>
                                                <li><Link to="mailto:info@cpcappadocia.com"><i className="icon far fa-envelope"></i> info@cpcappadocia.com</Link></li>
                                                <li><Link to="tel:+90(537) 040 67 42"><i className="icon far fa-phone"></i> +90(537) 040 67 42 </Link></li>
                                                <li>-</li>
                                            </ul>
                                        </div>
                                        <div className="separator"><span></span><span></span><span></span></div>
                                        <div className="newsletter">
                                            <h3>Abone Ol & İndirimleri Kaçırma</h3>
                                            <div className="text">İndirimlerden haberdar olmak İçin <br/><span>lütfen abone olun</span></div>
                                            <div className="newsletter-form">
                                                <form method="post" action="index.html">
                                                    <div className="form-group">
                                                        <span className="alt-icon far fa-envelope"></span>
                                                        <input type="email" name="email" placeholder="E-posta adresi" required />
                                                        <button type="submit" className="theme-btn btn-style-one clearfix">
                                                            <span className="btn-wrap">
                                                                <span className="text-one">Abone Ol</span>
                                                                <span className="text-two">Abone Ol</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">

                                    </ul>
                                </div>
                            </div>

                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2023 Crowne Plaza - Cappadocia. All Rights Reserved   |    Created by <Link to="https://pnrteknoloji.com" target="blank">PNR Technology</Link></div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>

        </>

    )
}

export default Main