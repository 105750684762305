import React from 'react'
import Startermenu from '../Allmenus/FirstMenu/Startermenu';
import Offer from '../Allmenus/FirstMenu/Offers'
import Reservation from '../Allmenus/FirstMenu/Reservation';
import Bredcom from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-2.jpg'
import Salatalar from "../Allmenus/FirstMenu/Salatalar";
import Burgerler from "../Allmenus/FirstMenu/burgerler";
import Makarnalar from "../Allmenus/FirstMenu/Makarnalar";
import Anayemekler from "../Allmenus/FirstMenu/Anayemekler";
import Tatlilar from "../Allmenus/FirstMenu/Tatlilar";
import Kidsmenu from "../Allmenus/FirstMenu/Kidsmenu";
import SıcakIcecekler from "../Allmenus/FirstMenu/SicakIcecekler";
import SogukIcecekler from "../Allmenus/FirstMenu/SogukIcecekler";
import Bira from "../Allmenus/FirstMenu/Bira";
import Aperitive from "../Allmenus/FirstMenu/Aperitive";
import Votka from "../Allmenus/FirstMenu/Votka";
import Gin from "../Allmenus/FirstMenu/Gin";
import Tekila from "../Allmenus/FirstMenu/Tekila";
import Rom from "../Allmenus/FirstMenu/Rom";
import Viski from "../Allmenus/FirstMenu/Viski";
import Konyak from "../Allmenus/FirstMenu/Konyak";
import Liquer from "../Allmenus/FirstMenu/Liquer";
import YerelIcki from "../Allmenus/FirstMenu/YerelIcki";
import Cocktail from "../Allmenus/FirstMenu/Cocktail";
import BeyazSaraplar from "../Allmenus/FirstMenu/BeyazSaraplar";
import KirmiziSaraplar from "../Allmenus/FirstMenu/KirmiziSaraplar";
import RoseSaraplar from "../Allmenus/FirstMenu/RoseSaraplar";
import Sampanya from "../Allmenus/FirstMenu/Sampanya";
import Header from '../HeaderOne/Main';


function Menuone() {
  return (

    <>

        <Header />

      <Bredcom
        subtitle="Menu"
        title="DELICIOUS & AMAZING"
        Img={Img}
      />
      <Startermenu />
      <Offer />
      <Salatalar />
      <Burgerler />
      <Makarnalar />
      <Anayemekler />
      <Tatlilar />
      <Kidsmenu />
      <SıcakIcecekler />
      <SogukIcecekler />
      <Bira />
      <Aperitive />
      <Votka />
      <Gin />
      <Tekila />
      <Rom />
      <Viski />
      <Konyak />
      <Liquer />
      <YerelIcki />
      <Cocktail />
      <BeyazSaraplar />
      <KirmiziSaraplar />
      <RoseSaraplar />
      <Sampanya />

    </>

  )
}

export default Menuone