import React, {useEffect, useState} from "react";
import FoodForms from "./FoodForms";
import Bredcrumb from '../Bredcrumb/Main'
import Img from "../../assets/images/background/banner-image-2.jpg";
import { db } from './firebase';
import {ref, set, getDatabase, onValue, update, remove, query, equalTo, orderByChild} from "firebase/database"

import { useUserAuth } from "./UserAuthContext";

function Foods() {


    const { logOut, user } = useUserAuth();
    const handleLogout = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error.message);
        }
    };

    const [iletisimVeriler, setIletisimVeriler] = useState({});
    const [currentId, setCurrentId]=useState('');
    const [searchValue, setSearchValue] = useState("");
    const [selectedColumn, setSelectedColumn] = useState("kategori");
    const [filteredData, setFilteredData] = useState([]);





    useEffect(() => {
        const dbRef = ref(getDatabase(), "iletisim");
        //const q = query(dbRef, orderByChild('timestamp'),endAt('0'));

        onValue(dbRef, (snapshot) => {
            if(snapshot.exists()){
                setIletisimVeriler({
                    ...snapshot.val()
                })
            }
            console.log(iletisimVeriler);
        });
    }, []);


    const veriEkle=(nesne)=>{

        if(currentId==''){
            set(ref(db, 'iletisim/' + nesne.timestamp),
                nesne
            ).then(() => {
                    console.log('Veri başarıyla eklendi');
                    //window.location.reload();
                }
            ).catch((error) => console.error('Veri ekleme hatası:', error));
        } else {
            update(ref(db, `iletisim/${currentId}`),
                nesne
            ).then(() => {
                    console.log('Veri başarıyla eklendi');
                    setCurrentId('')
                    //window.location.reload();
                }
            ).catch((error) => console.error('Veri ekleme hatası:', error));
        }
    }

    const veriSil = id => {
        if(window.confirm('Silmek istediğinizden emin misiniz ?')){
            remove(ref(db, `iletisim/${id}`))
                .then(() => {
                    console.log('Veri başarıyla silindi');
                })
                .catch((error) => {
                    console.error('Veri silme hatası:', error)
                });

        }
    }

    const handleSearch = () => {
        const dbRef = ref(getDatabase(), "iletisim");
        const q = query(dbRef, orderByChild(selectedColumn), equalTo(searchValue));
        onValue(q, (snapshot) => {
            if (snapshot.exists()) {
                setFilteredData({ ...snapshot.val() });
            }
        });
    };




    /*const veriEkle=(nesne)=>{
        addDoc(collection(db, 'iletisim'),{
            nesne
        }).then((docRef) => {
            console.log('Belge başarıyla eklendi:', docRef.id);
        })
            .catch((error) => {
                console.error('Belge eklenirken hata oluştu:', error);
            });

        console.log(nesne);
    }*/

    return (
        <>
            <Bredcrumb
                subtitle="Admin Menu"
                title="EKLE & ÇIKAR"
                Img={Img}
            />

            <section className="online-reservation inner-page">
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Yönetim Paneli</span></div>

                        <h2></h2>
                        <div className="text desc"></div>
                        <div className="text request-info"> </div>
                    </div>


                    <div className="row">

                        <div className="col-md-5">
                            <FoodForms {...({veriEkle,currentId,iletisimVeriler})}/>
                        </div>

                        <div className="col-md-7">
                            <div className="form-group input-group">

                                <select className="form-select" onChange={(e) => setSearchValue(e.target.value)}
                                        name="kategori" value={searchValue} >
                                    <option>Kategori Seçiniz</option>
                                    <option>Başlangıçlar</option>
                                    <option>Salatalar</option>
                                    <option>Ara Sıcaklar</option>
                                    <option>Makarnalar</option>
                                    <option>Burgerler</option>
                                    <option>Tatlılar</option>
                                    <option>Ana Yemekler</option>
                                    <option>Beyaz Şaraplar</option>
                                    <option>Roze Şaraplar</option>
                                    <option>Kırmızı Şaraplar</option>
                                    <option>Şampanya&Köpüren Şaraplar</option>
                                    <option>Sıcak İçecekler</option>
                                    <option>Soğuk İçecekler</option>
                                    <option>Bira</option>
                                    <option>Aperitif</option>
                                    <option>Votka</option>
                                    <option>Cin</option>
                                    <option>Tekila</option>
                                    <option>Rom</option>
                                    <option>Konyak</option>
                                    <option>Viski</option>
                                    <option>Likör</option>
                                    <option>Yerli İçki</option>
                                </select>
                                <select onChange={(e) => setSelectedColumn(e.target.value)}>
                                    <option value="kategori">Kategori</option>
                                </select>
                                <button onClick={handleSearch} className="theme-btn btn-style-one clearfix">
                                                            <span className="btn-wrap">
                                                                <span className="text-one">Ara</span>
                                                                <span className="text-two">Ara</span>
                                                            </span>
                                </button>
                            </div>
                            <table className="table table-borderless table-striped">
                                <thead className="thead-light">
                                <tr>
                                    <th>Yemek İsmi</th>
                                    <th>Detay</th>
                                    <th>Kategori</th>
                                    <th>Fiyat</th>
                                    <th>imageURL</th>
                                    <th>İslemler</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(filteredData).map((id) => {
                                    return <tr key={id}>
                                        <td><p className="text-white">{filteredData[id].isim}</p></td>
                                        <td><p className="text-white">{filteredData[id].detay}</p></td>
                                        <td><p className="text-white">{filteredData[id].kategori}</p></td>
                                        <td><p className="text-white">{filteredData[id].fiyat} ₺</p></td>
                                        <td>
                                            <img src={filteredData[id].imageUrl}  className="img-thumbnail"></img>
                                        </td>
                                        <td>
                                            <a className="btn text-primary" onClick={()=>setCurrentId(id)}>
                                                <i className="fas fa-pencil-alt"></i>
                                            </a>
                                            <a className="btn text-danger" onClick={()=>veriSil(id)}>
                                                <i className="fas fa-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="title-box centered">
                        <button onClick={handleLogout} className="theme-btn btn-style-one clearfix">
                                                            <span className="btn-wrap">
                                                                <span className="text-one">Çıkış Yap</span>
                                                                <span className="text-two">Çıkış Yap</span>
                                                            </span>
                        </button>
                    </div>
                </div>




            </section>


        </>
    )
}

export default Foods
