import React from "react";
import LobiFoods from "./lobiFoods";

function LobiAdmin() {
    return (

        <>
           <LobiFoods />
        </>


    );
}

export default LobiAdmin