import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import bg22 from '../../../assets/images/background/bg-22.png'
import menussaladone from '../../../assets/images/resource/menus-salad.jpg'
import h1 from "../../../assets/images/resource/h-1.png";
import v1 from "../../../assets/images/resource/v-1.png";
import leaf1 from "../../../assets/images/resource/leaf-1.png";
import {getDatabase, onValue, ref} from "firebase/database";

function Salatalar() {
    const [iletisimVeriler, setIletisimVeriler] = useState({});
    const [currentId, setCurrentId]=useState('');

    useEffect(() => {
        const yemeklerRef = ref(getDatabase(),'roommenu');
        onValue(yemeklerRef, (snapshot) => {
            if(snapshot.exists()){
                const data = snapshot.val();
                const filteredData = {};
                Object.keys(data).forEach((key) => {
                    if (data[key].kategori === 'Salatalar') {
                        filteredData[key] = data[key];
                    }
                });
                setIletisimVeriler(filteredData);
            }
            console.log(iletisimVeriler);
        });
    }, []);

    return (

        <>
            <section id="salatalar" className="menu-one">
                <div className="left-bg"><img src={bg22} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Salads</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Salatalar</h2>
                    </div>

                    <div className="row clearfix">
                        <div className="image-col col-lg-7 col-md-12 col-sm-12">
                            <div className="inner">
                                <div className="image"><img src={menussaladone} alt="" /></div>
                            </div>
                        </div>
                        <div className="menu-col col-lg-5 col-md-12 col-sm-12">
                            <div className="inner">

                                {Object.keys(iletisimVeriler).map(id=>{
                                    return <div className="dish-block" key={id}>
                                        <div className="inner-box">
                                            <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{iletisimVeriler[id].isim}</Link></h5></div><div className="price"><span>{iletisimVeriler[id].fiyat}</span><i
                                                className="fas fa-turkish-lira-sign fa-2xs align-baseline"></i></div></div>
                                            <div className="dish-image">
                                                <Link href="#">
                                                    {iletisimVeriler[id].imageIcon ? (
                                                        <img style={{ width: '100px'}} src={iletisimVeriler[id].imageIcon} alt="" />
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </Link>
                                            </div>
                                            <div className="text desc"><Link to="#">{iletisimVeriler[id].detay}</Link></div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                    <div className="text desc"><p>*Belirli bir yiyeceğe karşı alerjiniz var ise lütfen servis personelini bilgilendiriniz. *Please inform your order-taker of any allergy or special dietary requirements that we should be made aware of, when preparing your menu request.</p>
                        <p><img style={{ width: '20px'}} src={h1} alt="" title="" /> sağlıklı yiyecek / healthy food |&nbsp;
                             <img style={{ width: '20px'}} src={v1} alt="" title="" /> vejeteryan yiyecek vegetarian food |&nbsp;
                             <img style={{ width: '20px'}} src={leaf1} alt="" title="" /> hızlı ve taze yiyecekler / fast&fresh foods </p>
                    </div>
                </div>
            </section>

        </>


    )
}

export default Salatalar