import React, {useState, useRef, useEffect} from "react";

export default function FoodForms(props) {

    const formAlanlar={
        isim:'',
        detay:'',
        kategori:'',
        fiyat:'',
        imageUrl:'',
    }

    const [alanlar, setAlanlar]=useState(formAlanlar);

    useEffect(()=>{
        console.log(props.currentId);

        if(props.currentId==''){
            setAlanlar({
                ...formAlanlar
                })
            } else {
                setAlanlar({
                    ...props.iletisimVeriler[props.currentId]
                })
        }

    },[props.currentId,props.iletisimVeriler])

    const alanlarDegisti = (e) => {
        let alanIsim=e.target.name
        let alanDeger=e.target.value
        const timestamp = Date.now();

        setAlanlar({
            ...alanlar,[alanIsim]:alanDeger,timestamp
        })
    }


    const verileriKaydet = (e) => {
        e.preventDefault();
        //console.log(alanlar);
        props.veriEkle(alanlar);
    }


    return (
            <form onSubmit={verileriKaydet}>
                <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <i className="fas fa-bowl-food">


                            </i>
                        </div>
                    </div>
                    <input className="form-control" onChange={alanlarDegisti} name="isim" value={alanlar.isim || ""} placeholder="Yemek İsmini Giriniz"/>
                </div>

                <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <i className="fas fa-salad">


                            </i>
                        </div>
                    </div>
                    <input className="form-control" onChange={alanlarDegisti} name="detay" value={alanlar.detay || ""} placeholder="Yemek Detayını Giriniz"/>
                </div>

                <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <i className="fas fa-chevron-double-down">


                            </i>
                        </div>
                    </div>
                        <select className="form-select" onChange={alanlarDegisti} name="kategori" value={alanlar.kategori || ""} >
                            <option>Kategori Seçiniz</option>
                            <option>Başlangıçlar</option>
                            <option>Salatalar</option>
                            <option>Ara Sıcaklar</option>
                            <option>Makarnalar</option>
                            <option>Burgerler</option>
                            <option>Tatlılar</option>
                            <option>Ana Yemekler</option>
                            <option>Beyaz Şaraplar</option>
                            <option>Roze Şaraplar</option>
                            <option>Kırmızı Şaraplar</option>
                            <option>Şampanya&Köpüren Şaraplar</option>
                            <option>Sıcak İçecekler</option>
                            <option>Soğuk İçecekler</option>
                            <option>Bira</option>
                            <option>Aperitif</option>
                            <option>Votka</option>
                            <option>Cin</option>
                            <option>Tekila</option>
                            <option>Rom</option>
                            <option>Konyak</option>
                            <option>Viski</option>
                            <option>Likör</option>
                            <option>Yerli İçki</option>
                        </select>
                </div>

                <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <i className="fas fa-try">


                            </i>
                        </div>
                    </div>
                    <input className="form-control" onChange={alanlarDegisti} name="fiyat" value={alanlar.fiyat || ""} placeholder="Fiyat Giriniz"/>
                </div>

                <div className="form-group input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <i className="fas fa-image">


                            </i>
                        </div>
                    </div>
                    <input className="form-control" onChange={alanlarDegisti} name="imageUrl" value={alanlar.imageUrl || ""} placeholder="Resim URL adresi"/>
                </div>

                <div className="form-group">
                    <input type="submit" value="Kaydet" className="btn btn-success btn-block"/>

                </div>
            </form>
    )
}