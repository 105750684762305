import React, { useState } from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import GoogleButton from "react-google-button";
import { useUserAuth } from "./UserAuthContext";
import Img from "../../assets/images/background/banner-image-2.jpg";
import Bredcrumb from "../Bredcrumb/Main";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { logIn, googleSignIn } = useUserAuth();
    const navigate = useNavigate();
    const { user } = useUserAuth();

    if (user) {
        return <Navigate to="/Foods" />;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await logIn(email, password);
            navigate("/Foods");
        } catch (err) {
            setError(err.message);
        }
    };

    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        try {
            await googleSignIn();
            navigate("/Foods");
        } catch (error) {
            console.log(error.message);
        }
    };


    return (
        <>
            <Bredcrumb
                subtitle="Admin Menu"
                title="EKLE & ÇIKAR"
                Img={Img}
            />
            <section className="online-reservation inner-page">
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Yönetim Paneli</span></div>

                        <h2></h2>
                        <div className="text desc"></div>
                        <div className="text request-info"> </div>
                    </div>
            <div className="row">
                {error && <Alert variant="danger">{error}</Alert>}
                <Form className="form-group" onSubmit={handleSubmit}>
                    <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            placeholder="E-posta Adresi"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="form-group" controlId="formBasicPassword">
                        <Form.Control
                            type="password"
                            placeholder="Şifre"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-grid gap-2">
                        <Button variant="primary" type="Submit">
                            Giriş Yap
                        </Button>
                    </div>
                </Form>
                <hr />
               {/* <div className="title-box centered">
                    <GoogleButton
                        className="g-btn"
                        type="dark"
                        onClick={handleGoogleSignIn}
                    />
                </div>*/}
            </div>

                </div>

            </section>
        </>
    );
};

export default Login;