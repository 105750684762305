import React, {useState,useEffect} from 'react'
import Offers from '../Allmenus/FirstMenu/Offers'
import Reservation from '../Allmenus/FirstMenu/Reservation'
import Appetizers from '../Allmenus/Thrdmenu/Appetizers'
import Privateevent from '../Allmenus/Thrdmenu/Privateevent'
import Bredcrumb from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-2.jpg'
import Deliciousmenu from "../Allmenus/Fourmenu/Deliciousmenu";
import Salads from "../Allmenus/Thrdmenu/salads";
import Arasicak from "../Allmenus/Thrdmenu/arasicaklar";
import Makarnalar from "../Allmenus/Thrdmenu/makarnalar";
import Burgerler from "../Allmenus/Thrdmenu/burgerler";
import Tatlilar from "../Allmenus/Thrdmenu/tatlilar";
import Anayemekler from "../Allmenus/Thrdmenu/anayemekler";
import BeyazSaraplar from "../Allmenus/Thrdmenu/beyaz_saraplar";
import RozeSaraplar from "../Allmenus/Thrdmenu/roze_saraplar";
import KirmiziSaraplar from "../Allmenus/Thrdmenu/kirmizi_saraplar";
import Sampanya from "../Allmenus/Thrdmenu/sampanya";
import SicakIcecekler from "../Allmenus/Thrdmenu/sicak_icecekler";
import SogukIcecekler from "../Allmenus/Thrdmenu/soguk_icecekler";
import Bira from "../Allmenus/Thrdmenu/bira";
import Aperitif from "../Allmenus/Thrdmenu/aperitif";
import Gin from "../Allmenus/Thrdmenu/gin";
import Tekila from "../Allmenus/Thrdmenu/tekila";
import Rom from "../Allmenus/Thrdmenu/rom";
import Konyak from "../Allmenus/Thrdmenu/konyak";
import Viski from "../Allmenus/Thrdmenu/viski";
import Likor from "../Allmenus/Thrdmenu/likor";
import YerliIcki from "../Allmenus/Thrdmenu/yerliicki";
import Votka from "../Allmenus/Thrdmenu/votka";
import Header from '../Header/Main';



function Menuthree() {


  return (

    <>

      <Header/>

      <Bredcrumb
        subtitle="Menu"
        title="DELICIOUS & AMAZING"
        Img={Img}
      />
        <Appetizers />
        <Salads />
        <Arasicak />
        <Makarnalar />
        <Burgerler />
        <Anayemekler />
        <Tatlilar />
        <SicakIcecekler />
        <SogukIcecekler />
        <YerliIcki />
        <Bira />
        <BeyazSaraplar />
        <RozeSaraplar />
        <KirmiziSaraplar />
        <Sampanya />
        <Aperitif />
        <Votka />
        <Gin />
        <Tekila />
        <Rom />
        <Konyak />
        <Viski />
        <Likor />

    </>

  )
}

export default Menuthree