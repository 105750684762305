import React from "react";
import Foods from "./Foods";

function Admin() {
    return (

        <>
                <Foods />
        </>

    );
}

export default Admin