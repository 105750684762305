import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBjl2tcDSI87KWiEAG6m5Khb-dk6xIlsDI",
    authDomain: "qrmenuapp-1517f.firebaseapp.com",
    databaseURL: "https://qrmenuapp-1517f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "qrmenuapp-1517f",
    storageBucket: "qrmenuapp-1517f.appspot.com",
    messagingSenderId: "665023511131",
    appId: "1:665023511131:web:fde1465486e79df0649d7e"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig, {
    experimentalForceLongPolling: true, // this line
    useFetchStreams: false, // and this line
});
const db = getDatabase(firebaseApp);
export { db }
export const auth = getAuth(firebaseApp);
export default firebaseApp