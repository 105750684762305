import React from "react";
import RoomFoods from "./roomFoods";

function RoomAdmin() {
    return (

        <>
           <RoomFoods />
        </>


    );
}

export default RoomAdmin