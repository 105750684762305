import React from 'react'
import Startermenu from '../Allmenus/LobiMenu/Startermenu';
import Offer from '../Allmenus/FirstMenu/Offers'
import Reservation from '../Allmenus/FirstMenu/Reservation';
import Bredcom from '../Bredcrumb/Main'
import Img from '../../assets/images/background/banner-image-2.jpg'
import Aperitifler from "../Allmenus/LobiMenu/Aperitifler";
import Durumler from "../Allmenus/LobiMenu/Durumler";
import Noodle from "../Allmenus/LobiMenu/Noodle";
import Tatlilar from "../Allmenus/LobiMenu/Tatlilar";
import SicakIcecekler from "../Allmenus/LobiMenu/SicakIcecekler";
import SogukIcecekler from "../Allmenus/LobiMenu/SogukIcecekler";
import Bira from "../Allmenus/LobiMenu/Bira";
import Aperitive from "../Allmenus/LobiMenu/Aperitive";
import Votka from "../Allmenus/LobiMenu/Votka";
import Gin from "../Allmenus/LobiMenu/Gin";
import Rom from "../Allmenus/LobiMenu/Rom";
import Tekila from "../Allmenus/LobiMenu/Tekila";
import Viski from "../Allmenus/LobiMenu/Viski";
import Konyak from "../Allmenus/LobiMenu/Konyak";
import Liquer from "../Allmenus/LobiMenu/Liquer";
import YerelIcki from "../Allmenus/LobiMenu/YerelIcki";
import Cocktail from "../Allmenus/LobiMenu/Cocktail";
import BeyazSaraplar from "../Allmenus/LobiMenu/BeyazSaraplar";
import KirmiziSaraplar from "../Allmenus/LobiMenu/KirmiziSaraplar";
import RoseSaraplar from "../Allmenus/LobiMenu/RoseSaraplar";
import Sampanya from "../Allmenus/LobiMenu/Sampanya";
import Header from '../HeaderLobi/Main';


function Menuone() {
    return (

        <>

            <Header />

            <Bredcom
                subtitle="Menu"
                title="DELICIOUS & AMAZING"
                Img={Img}
            />
            <Startermenu />
            <Aperitifler />
            <Durumler />
            <Noodle />
            <Tatlilar />
            <SicakIcecekler />
            <SogukIcecekler />
            <Bira />
            <Aperitive />
            <Votka />
            <Gin />
            <Rom />
            <Tekila />
            <Viski />
            <Konyak />
            <Liquer />
            <YerelIcki />
            <Cocktail />
            <BeyazSaraplar />
            <KirmiziSaraplar />
            <RoseSaraplar />
            <Sampanya />

        </>

    )
}

export default Menuone