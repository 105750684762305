import React, {  useState, useEffect} from 'react'
import { Route, Routes , useLocation} from 'react-router-dom';
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import Home from '../components/Home/Main';
import About from '../components/About/Main';
import Chefs from '../components/Chef/Main';
import Contact from '../components/Contact/Main';
import Menu from '../components/Menu/Main';
import Menuone from '../components/Menu/Menuone';
import Menutwo from '../components/Menu/Menutwo'
import Menuthree from '../components/Menu/Menuthree';
import Menufour from '../components/Menu/Menufour';
import Book from '../components/Book/Main';
import Admin from '../components/Admin/Admin'
// import Scrool from '../components/Menhome/Scrool';
import Hometwo from '../components/Headertwo/Main'
import Homethree from '../components/Homethree/Homethree'
import Foods from "../components/Admin/Foods";
import Login from "../components/Admin/Login";
import Signup from "../components/Admin/Signup";
import ProtectedRoute from "../components/Admin/protected-route";
import {UserAuthContextProvider} from "../components/Admin/UserAuthContext";
import RoomFoods from "../components/roomAdmin/roomFoods";
import RoomAdmin from "../components/roomAdmin/roomAdmin";
import LobiAdmin from "../components/lobiAdmin/lobiAdmin";
import Menulobi from "../components/Menu/Menulobi";



function Index() {

  const [homepage, sethomepage] = useState(false)
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/homethree"){
      sethomepage(false)
    }else{
      sethomepage(true)
    }
  }, [location])

  // const location = useLocation()
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
  }, [path]);

  return (

      <>

        {homepage}

        <UserAuthContextProvider>
          <Routes>
            <Route path="/hometwo" element={<Hometwo />} />
            <Route path="/homethree" element={<Homethree />} />
            //<Route path="/home" element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/menuone" element={<Menuone />} />
            <Route path="/menulobi" element={<Menulobi />} />
            <Route path="/menutwo" element={<Menutwo />} />
            <Route path="/" element={<Menuthree />} />
            <Route path="/menufour" element={<Menufour />} />
            <Route path="/about" element={<About />} />
            <Route path="/ourchefs" element={<Chefs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/reservation" element={<Book />} />
            <Route
                path="/roomFoods"
                element={
                  <ProtectedRoute>
                    <RoomFoods />
                  </ProtectedRoute>
                }

            />
            <Route
                path="/Foods"
                element={
                  <ProtectedRoute>
                    <Foods />
                  </ProtectedRoute>
                }

            />
            <Route path="/Admin" element={<Login />} />
            <Route path="/Signup12345678" element={<Signup />} />
            <Route path="/Foods" element={<Foods />} />
            <Route path="/Admin" element={<Admin />} />
            <Route path="/RoomAdmin" element={<RoomAdmin />} />
            <Route path="/lobiAdmin" element={<LobiAdmin />} />
          </Routes>
        </UserAuthContextProvider>


        <Footer />
      </>

  )
}

export default Index