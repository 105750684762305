import React, {useEffect, useRef, useState} from 'react'
import { Link } from 'react-router-dom';
import bg19 from '../../../assets/images/background/bg-19.png'
import {getDatabase, onValue, ref, orderByChild, off, child, equalTo} from "firebase/database";



function Makarnalar() {
    const [iletisimVeriler, setIletisimVeriler] = useState({});
    const [currentId, setCurrentId]=useState('');

    useEffect(() => {
        const yemeklerRef = ref(getDatabase(),'iletisim');
        onValue(yemeklerRef, (snapshot) => {
            if(snapshot.exists()){
                const data = snapshot.val();
                const filteredData = {};
                Object.keys(data).forEach((key) => {
                    if (data[key].kategori === 'Makarnalar') {
                        filteredData[key] = data[key];
                    }
                });
                setIletisimVeriler(filteredData);
            }
            console.log(iletisimVeriler);
        });
    }, []);


    return (
        <>

            <section id="makarnalar" className="menu-one">

                <div className="right-bg"><img src={bg19} alt="" title="" /></div>
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>pasta's</span></div>

                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/separator.svg').default} alt='mySvgImage' />
                        </div>

                        <h2>Makarnalar</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                {Object.keys(iletisimVeriler).map(id=>{
                                    return <div className="dish-block" key={id}>
                                        <div className="inner-box">
                                            <div className="dish-image"><Link href="#"><img src={iletisimVeriler[id].imageUrl} alt="" /></Link></div>
                                            <div className="title clearfix"><div className="ttl clearfix"><h5><Link href="#">{iletisimVeriler[id].isim}</Link></h5></div><div className="price"><span>{iletisimVeriler[id].fiyat}</span><i
                                                className="fa-regular fa-turkish-lira-sign fa-2xs align-baseline"></i></div></div>
                                            <div className="text desc"><Link href="#">{iletisimVeriler[id].detay}</Link></div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Makarnalar