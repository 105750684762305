import React, {useState, useEffect, useRef} from 'react'
import { Link } from "react-router-dom";
import logo from '../../assets/images/resource/cp-sidebar-logo.png'
import logonew from '../../assets/images/cp-logo.png'
//import imgone from '../../assets/images/resource/menu-image-1.jpg'
//import imgtwo from '../../assets/images/resource/menu-image-2.jpg'
//import Imgthree from '../../assets/images/resource/menu-image-3.jpg'
//import Imgfour from '../../assets/images/resource/menu-image-4.jpg'



function Main() {



    const [active, setActive] = useState();
    //const [show, setShow] = useState();
    //const [menu, setMenu] = useState();
    //const [page, setPage] = useState();
    //const [drop, setDrop] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled,setScrolled]=useState(false);
    const [Nav,setNav]=useState(true);





    const handleScroll=() => {

        const offset=window.scrollY;
        setlastscrool(offset);

        if(offset > 1000 && offset < lastscrool){
            setNav(true);
            setScrolled(true);
        }

        else if(offset > 1000 ){
            setNav(false)
        }

        else if(offset > 200){
            setNav(true);
            setScrolled(true);
        }

        else{
            setScrolled(false);
            setNav(true);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',handleScroll)
    })



    return (

        <>

            {active &&
            <div className="menu-backdrop" style={{ opacity: "1"  , visibility: "visible"}} onClick={() => setActive(false)}></div> }

            <section className={`hidden-bar ${active && "visible-sidebar"}`} onClick={() => setActive(false)} >
                <div className="inner-box">
                    <div className="cross-icon hidden-bar-closer" onClick={() => setActive(false)} ><span className="far fa-close"> </span></div>
                    <div className="logo-box"><Link to="/" title="Crowne Plaza - Cappadocia Menu"><img src={logo} alt="" title="Crowne Plaza - Cappadocia Menu" />
                    </Link></div>

                    <div className="side-menu">
                        <ul className="navigation clearfix">
                            <li><a href="#salatalar">Salatalar</a></li>
                            <li><a href="#aperitifler">Aperitifler</a></li>
                            <li><a href="#dürümler">Dürümler</a></li>
                            <li><a href="#noodles">Noodles</a></li>
                            <li><a href="#tatlılar">Tatlılar</a></li>
                            <li><a href="#sicakicecekler">Sıcak İçecekler</a></li>
                            <li><a href="#sogukicecekler">Soğuk İçecekler</a></li>
                            <li><a href="#bira">Bira</a></li>
                            <li><a href="#votka">Votka</a></li>
                            <li><a href="#gin">Cin</a></li>
                            <li><a href="#rom">Rom</a></li>
                            <li><a href="#tekila">Tekila</a></li>
                            <li><a href="#viski">Viski</a></li>
                            <li><a href="#konyak">Konyak</a></li>
                            <li><a href="#likör">Likör</a></li>
                            <li><a href="#yerelicki">Yerel İçki</a></li>
                            <li><a href="#kokteyl">Kokteyl</a></li>
                            <li><a href="#beyazsaraplar">Beyaz Şaraplar</a></li>
                            <li><a href="#kirmizisaraplar">Kırmızı Şaraplar</a></li>
                            <li><a href="#rosesaraplar">Rose&Blush Şaraplar</a></li>
                            <li><a href="#kopurensaraplar">Şampanya&Köpüren Şaraplar</a></li>

                        </ul>
                    </div>

                    <h2>Crowne Plaza - Cappadocia, Menu</h2>
                    <ul className="info">
                        <li>Yeni Mahalle, Milli Irade Caddesi  <br /> No 2/1, 50200 Merkez/Nevşehir </li>
                        <li>-</li>
                        <li><Link to="mailto:info@cpcappadocia.com">info@cpcappadocia.com</Link></li>
                    </ul>
                    <div className="separator"><span></span></div>
                    <div className="booking-info">
                        <div className="bk-title">Oda Servisi İçin</div>
                        <div className="bk-no"><Link to="tel:+90(537) 040 67 42">+90(537) 040 67 42</Link></div>
                    </div>

                </div>
            </section>
            {Nav &&
            <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                <div className="header-top">
                    <div className="auto-container">
                        <div className="inner clearfix">
                            <div className="top-left clearfix">
                                <ul className="top-info clearfix">
                                    <li>Crowne Plaza - Cappadocia, Roof Katı </li>
                                    <li>Saatler: 18.00 pm - 24.00pm</li>
                                </ul>
                            </div>
                            <div className="top-right clearfix">
                                <ul className="top-info clearfix">
                                    <li><Link to="tel:+90(384) 228 50 00"><i className="icon far fa-phone"></i> +90(384) 228 50 00 </Link> </li>
                                    <li><Link to="mailto:roof@cpcappadocia.com"><i className="icon far fa-envelope"></i> roof@cpcappadocia.com</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-upper header-fixed" >
                    <div className="auto-container">
                        <div className="main-box d-flex justify-content-between align-items-center flex-wrap">

                            <div className="links-box clearfix">
                                <div className="nav-toggler">
                                    <button className="hidden-bar-opener">
                                            <span className="hamburger" onClick={() => setActive(true)}>
                                                <span className="top-bun"></span>
                                                <span className="meat"></span>
                                                <span className="bottom-bun"></span>
                                            </span>
                                    </button>
                                </div>
                            </div>

                            <div className="logo-box">
                                <div className="logo"><Link to="/" title="Crowne Plaza - Cappadocia, Roof Menu"><img src={logonew} alt="" title="Crowne Plaza - Cappadocia, Roof Menu" /></Link></div>
                            </div>

                            <div className="nav-box clearfix">

                                <div className="nav-outer clearfix">
                                    <nav className="main-menu">
                                        <ul className="navigation clearfix">
                                            <li><Link to="/about">About Us</Link></li>
                                            <li><Link to="/ourchefs">Our chefs</Link></li>
                                            <li><Link to="#">Blog</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>

                            </div>

                            <div className="link link-btn">
                                <Link to="/reservation" className="theme-btn btn-style-one clearfix">
                                        <span className="btn-wrap">
                                            <span className="text-one" >find a table</span>
                                            <span className="text-two" >find a table</span>
                                        </span>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </header> }

        </>

    )
}

export default Main
